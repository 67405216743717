import { Link } from "react-router-dom";
import { FaSearch, FaGlobe } from "react-icons/fa";

export default function Nav() {
  return (
    <nav className="navbar navbar-white bg-white justify-content-between m-1">
      <Link to="/home">
        <img
          src={require("../Assets/Images/logo.png")}
          alt=""
          className="logo"
        />
      </Link>
      <div>
        <button
          className="btn my-2 my-sm-0"
          type="submit"
          style={{ fontSize: 20 }}
        >
          <FaSearch />
        </button>
        <button
          className="btn my-2 my-sm-0"
          type="submit"
          style={{ fontSize: 20 }}
        >
          <FaGlobe />
        </button>
      </div>
    </nav>
  );
}
