import RowContent from "../Components/RowContent";

export default function Home() {
  var btn_search = [];
  var text_row_content = [];
  const textSearch = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  for (var i = 0; i < textSearch.length; i++) {
    btn_search.push(
      <a href={"#" + textSearch[i]} key={i}>
        <button className="btn-text-search">{textSearch[i]}</button>
      </a>
    );
    text_row_content.push(<RowContent text={textSearch[i]} key={i} />);
  }

  return (
    <>
      {/* Header */}
      <div style={{ margin: "auto", maxWidth: "1136px" }}>
        <h1 className="title-header">Crypto Thai Keyword</h1>
        <p style={{ marginBottom: 64 }}>
          Learn all of the most important blockchain and cryptocurrency terms
          and jargon here.
        </p>
      </div>
      {/* button search */}
      <div className="m-button" id="m">
        <div className="line-button" style={{ margin: "auto" }}>
          <div className="grid-button">
            <div className="jPdAGF">
              <div className="ldtDko">{btn_search}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="line-content" style={{ margin: "auto" }}>
        {text_row_content}
      </div>
    </>
  );
}
