import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import Nav from './Layouts/Nav';
import { Outlet } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Nav></Nav>
      <Outlet></Outlet>
    </div>
  );
}

export default App;
