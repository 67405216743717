import axios from "axios";
import { useEffect, useState } from "react";



export default function RowContent({ text }) {
  const [content, setContent] = useState(null);
  var contentList = [];

  useEffect(() => {
    // let url = `https://crypto-keyword-api.rinen.dev/items/content?limit=1000&fields%5B%5D=contents&fields%5B%5D=label&fields%5B%5D=status&fields%5B%5D=topic&fields%5B%5D=id&sort%5B%5D=id&page=1&search=A&filter=%7B%22_and%22:%5B%7B%22_and%22:%5B%7B%22topic%22:%7B%22_starts_with%22:%22${text}%22%7D%7D%5D%7D,%7B%22status%22:%7B%22_neq%22:%22archived%22%7D%7D%5D%7D&meta%5B%5D=filter_count&meta%5B%5D=total_count`;
   let url = `https://lxawe471.directus.app/items/contents?limit=1000&fields%5B%5D=contents&fields%5B%5D=label&fields%5B%5D=status&fields%5B%5D=topic&fields%5B%5D=id&sort%5B%5D=id&page=1&search=A&filter=%7B%22_and%22:%5B%7B%22_and%22:%5B%7B%22topic%22:%7B%22_starts_with%22:%22${text}%22%7D%7D%5D%7D,%7B%22status%22:%7B%22_neq%22:%22archived%22%7D%7D%5D%7D&meta%5B%5D=filter_count&meta%5B%5D=total_count`;
    axios
      .get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        setContent(res.data.data);
      });
    // fetch(url, { mode: "no-cors" }).then((res) => {
    //   console.log(res.json());
    //   // setContent(res.data.data);
    // });
  },[]);

  if (!content) return null;

  for (let i = 0; i < content.length; i++) {
    contentList.push(
      <a className="a-content" key={i}>
        <h2 className="header-content">{content[i].topic}</h2>
        {/* <div className="description">{content[i].contents}</div> */}
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: content[i].contents }}
        />
      </a>
    );
  }

  return (
    <div id={text} className="content">
      <div className="krszTj">
        <div>
          <div className="hnJUdi">{text}</div>
        </div>
        <div>{contentList}</div>
      </div>
    </div>
  );
}
